export type Size = "none" | "hairline" | "sm" | "md" | "md-alt" | "lg" | "xl" | "2xl" | "xl-alt" | "2xl-alt" | "3xl" | "3xl-alt" | "4xl";

export type SizeMap = {
  [key in Size]: string;
};

// These are currently arbitrary values that will be confirmed with Center / Mati
export const sizeClassMap: SizeMap = {
  none: "h-0",
  hairline: "h-px",
  sm: "h-1",
  md: "h-2 lg:h-3",
  "md-alt": "h-4 lg:h-5",
  lg: "h-5 lg:h-6",
  xl: "h-8 lg:h-10 xl:h-12",
  "xl-alt": "h-0 lg:h-10 xl:h-12",
  "2xl": "h-12 lg:h-14 xl:h-16",
  "2xl-alt": "h-0 lg:h-14 xl:h-16",
  "3xl": "h-14 lg:h-16 xl:h-20",
  "3xl-alt": "h-14 lg:h-32 xl:h-42",
  "4xl": "h-36 lg:h-40 xl:h-42",
};
