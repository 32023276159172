import { Theme } from "apps/website/maps/Theme.map";
import { Breakpoint, BreakpointMap } from "apps/website/maps/Breakpoint.map";

export const availableTags = [ "h1", "h2", "h3", "h4", "h5", "h6", "p", "span", "address", "strong", "a", "div", "u", "s", "blockquote", "figcaption" ] as const;

export const colorClassMap = {
  inherit: "text-[var(--color-text-inherit)]",
  default: "text-[var(--color-text-default)] transition transition-color ease-in-out duration-300",
  secondary: "text-[var(--color-text-secondary)]",
  tertiary: "text-[var(--color-text-tertiary)]",
  disabled: "text-[var(--color-text-disabled)]",
  iconDisabled: "text-[var(--color-text-icon-disabled)] text-opacity-50",
  error: "text-[var(--color-text-error)]",
  required: "text-[var(--color-text-required)]",
  success: "text-[var(--color-text-success)]",
};

export const displayClassMap = {
  default: "font-default",
  title: "font-display uppercase",
  subtitle: "font-display sentence",
  button: "font-display",
};

export const defaultSizeClassMap = {
  inherit: "text-size-inherit",
  "5xs": "text-5xs",
  "4xs": "text-4xs",
  "3xs": "text-3xs",
  "2xs": "text-2xs",
  xs: "text-xs",
  sm: "text-sm",
  default: "text-base",
  md: "text-md",
  lg: "text-lg",
  xl: "text-xl",
  "2xl": "text-2xl",
  "3xl": "text-3xl",
  "4xl": "text-4xl",
  "5xl": "text-5xl",
  "6xl": "text-6xl",
} as const;

export const smallSizeClassMap = {
  "5xs": "sm:text-5xs",
  "4xs": "sm:text-4xs",
  "3xs": "sm:text-3xs",
  "2xs": "sm:text-2xs",
  xs: "sm:text-xs",
  sm: "sm:text-sm",
  default: "sm:text-base",
  md: "sm:text-md",
  lg: "sm:text-lg",
  xl: "sm:text-xl",
  "2xl": "sm:text-2xl",
  "3xl": "sm:text-3xl",
  "4xl": "sm:text-4xl",
  "5xl": "sm:text-5xl",
  "6xl": "sm:text-6xl",
};

export const mediumSizeClassMap = {
  "5xs": "md:text-5xs",
  "4xs": "md:text-4xs",
  "3xs": "md:text-3xs",
  "2xs": "md:text-2xs",
  xs: "md:text-xs",
  sm: "md:text-sm",
  default: "md:text-base",
  md: "md:text-md",
  lg: "md:text-lg",
  xl: "md:text-xl",
  "2xl": "md:text-2xl",
  "3xl": "md:text-3xl",
  "4xl": "md:text-4xl",
  "5xl": "md:text-5xl",
  "6xl": "md:text-6xl",
};

export const largeSizeClassMap = {
  "5xs": "lg:text-5xs",
  "4xs": "lg:text-4xs",
  "3xs": "lg:text-3xs",
  "2xs": "lg:text-2xs",
  xs: "lg:text-xs",
  sm: "lg:text-sm",
  default: "lg:text-base",
  md: "lg:text-md",
  lg: "lg:text-lg",
  xl: "lg:text-xl",
  "2xl": "lg:text-2xl",
  "3xl": "lg:text-3xl",
  "4xl": "lg:text-4xl",
  "5xl": "lg:text-5xl",
  "6xl": "lg:text-6xl",
};

export const extraLargeSizeClassMap = {
  "5xs": "xl:text-5xs",
  "4xs": "xl:text-4xs",
  "3xs": "xl:text-3xs",
  "2xs": "xl:text-2xs",
  xs: "xl:text-xs",
  sm: "xl:text-sm",
  default: "xl:text-base",
  md: "xl:text-md",
  lg: "xl:text-lg",
  xl: "xl:text-xl",
  "2xl": "xl:text-2xl",
  "3xl": "xl:text-3xl",
  "4xl": "xl:text-4xl",
  "5xl": "xl:text-5xl",
  "6xl": "xl:text-6xl",
};

export const twoExtraLargeSizeClassMap = {
  "5xs": "2xl:text-5xs",
  "4xs": "2xl:text-4xs",
  "3xs": "2xl:text-3xs",
  "2xs": "2xl:text-2xs",
  xs: "2xl:text-xs",
  sm: "2xl:text-sm",
  default: "2xl:text-base",
  md: "2xl:text-md",
  lg: "2xl:text-lg",
  xl: "2xl:text-xl",
  "2xl": "2xl:text-2xl",
  "3xl": "2xl:text-3xl",
  "4xl": "2xl:text-4xl",
  "5xl": "2xl:text-5xl",
  "6xl": "2xl:text-6xl",
};

export const threeExtraLargeSizeClassMap = {
  "5xs": "3xl:text-5xs",
  "4xs": "3xl:text-4xs",
  "3xs": "3xl:text-3xs",
  "2xs": "3xl:text-2xs",
  xs: "3xl:text-xs",
  sm: "3xl:text-sm",
  default: "3xl:text-base",
  md: "3xl:text-md",
  lg: "3xl:text-lg",
  xl: "3xl:text-xl",
  "2xl": "3xl:text-2xl",
  "3xl": "3xl:text-3xl",
  "4xl": "3xl:text-4xl",
  "5xl": "3xl:text-5xl",
  "6xl": "3xl:text-6xl",
};

export const legacySizeCollectionMap: Record<string, BreakpointSize> = {
  titleXl: { default: "4xl", xl: "5xl", "2xl": "6xl" }, // default: 80px , xl: 120px, 2xl: 200px
  titleLg: { default: "3xl", lg: "4xl" }, // default: 48px , lg: 80px
  titleMd: { default: "lg", lg: "2xl" }, // default: 25px , lg: 40px
  titleSm: { default: "sm", lg: "lg" }, // default: 18px, lg: 25px
  titleXs: { default: "2xs", lg: "xs" }, // default: 14px, lg: 16px
  bodyLg: { default: "default", lg: "xl" }, // default: 20px, lg: 30px
  base: { default: "2xs", lg: "default" }, // default: 14px, lg: 20px
  bodySm: { default: "2xs", lg: "sm" }, // default: 14px, lg: 18px
  body2Xs: { default: "4xs", lg: "sm" }, // default: 11px, lg: 18px
} as const;

export const sizeCollectionMap: Record<number, BreakpointSize> = {
  1: { default: "4xl", xl: "5xl", "2xl": "6xl" }, // default: 80px , xl: 120px, 2xl: 200px
  2: { default: "3xl", lg: "4xl" }, // default: 48px , lg: 80px
  3: { default: "lg", lg: "2xl" }, // default: 24px , lg: 40px
  4: { default: "default", lg: "xl" }, // default: 20px, lg: 30px
  5: { default: "sm", lg: "xl" }, // default: 18px, lg: 30px
  6: { default: "sm", lg: "lg" }, // default: 18px, lg: 24px
  7: { default: "2xs", lg: "sm" }, // default: 14px, lg: 18px
  8: { default: "2xs", lg: "xs" }, // default: 14px, lg: 16px
  9: { default: "3xs", lg: "default" }, // default: 12px, lg: 20px
  10: { default: "4xs", lg: "2xs" }, // default: 11px, lg: 14px
} as const;

export const alignClassMap = {
  inherit: "text-align-inherit",
  default: "text-left",
  right: "text-right",
  center: "text-center",
};

export const smallAlignClassMap = {
  inherit: "sm:text-align-inherit",
  default: "sm:text-left",
  right: "sm:text-right",
  center: "sm:text-center",
};

export const mediumAlignClassMap = {
  inherit: "md:text-align-inherit",
  default: "md:text-left",
  right: "md:text-right",
  center: "md:text-center",
};

export const largeAlignClassMap = {
  inherit: "lg:text-align-inherit",
  default: "lg:text-left",
  right: "lg:text-right",
  center: "lg:text-center",
};

export const extraLargeAlignClassMap = {
  inherit: "xl:text-align-inherit",
  default: "xl:text-left",
  right: "xl:text-right",
  center: "xl:text-center",
};

export const twoExtraLargeAlignClassMap = {
  inherit: "2xl:text-align-inherit",
  default: "2xl:text-left",
  right: "2xl:text-right",
  center: "2xl:text-center",
};

export const threeExtraLargeAlignClassMap = {
  inherit: "3xl:text-align-inherit",
  default: "3xl:text-left",
  right: "3xl:text-right",
  center: "3xl:text-center",
};

export const defaultWhitespaceClassMap = {
  default: "whitespace-normal",
  preWrap: "whitespace-pre-line",
  noWrap: "whitespace-nowrap",
  balance: "whitespace-normal text-balance",
};
export const smallWhitespaceClassMap = {
  default: "sm:whitespace-normal",
  preWrap: "sm:whitespace-pre-line",
  noWrap: "sm:whitespace-nowrap",
};
export const mediumWhitespaceClassMap = {
  default: "md:whitespace-normal",
  preWrap: "md:whitespace-pre-line",
  noWrap: "md:whitespace-nowrap",
};
export const largeWhitespaceClassMap = {
  default: "lg:whitespace-normal",
  preWrap: "lg:whitespace-pre-line",
  noWrap: "lg:whitespace-nowrap",
};
export const extraLargeWhitespaceClassMap = {
  default: "xl:whitespace-normal",
  preWrap: "xl:whitespace-pre-line",
  noWrap: "xl:whitespace-nowrap",
};
export const twoExtraLargeWhitespaceClassMap = {
  default: "2xl:whitespace-normal",
  preWrap: "2xl:whitespace-pre-line",
  noWrap: "2xl:whitespace-nowrap",
};
export const threeExtraLargeWhitespaceClassMap = {
  default: "3xl:whitespace-normal",
  preWrap: "3xl:whitespace-pre-line",
  noWrap: "3xl:whitespace-nowrap",
};

export const stickerSizeMap = {
  default: "text-base",
};

export const textStickerThemeMap: StickerThemeMap = {
  default: "bg-brand text-black",
  brand: "bg-light-grey text-black",
  "brand-alt": "bg-light-grey text-black",
  "light-grey": "bg-orange text-black",
};

type StickerThemeMap = {
  [key in Theme]?: string;
};
export type AvailableTag = typeof availableTags[number];
export type Align = keyof typeof alignClassMap;
export type Color = keyof typeof colorClassMap;
export type Display = keyof typeof displayClassMap;
export type Size = keyof typeof defaultSizeClassMap;
export type SizeCollection = keyof typeof legacySizeCollectionMap;
export type Whitespace = keyof typeof defaultWhitespaceClassMap;
export type BreakpointSize = {
  [key in Breakpoint]?: Size;
};
export type BreakpointAlign = {
  [key in Breakpoint]?: Align;
};
export type BreakpointWhiteSpace = {
  [key in Breakpoint]?: Whitespace;
};
export type StickerSize = keyof typeof stickerSizeMap;
export type TitleStickerSize = "0.5em" | "0.6em" | "0.7em" | "0.8em" | "0.9em" | "1em" | "1.1em" | "1.2em" | "1.3em" | "1.4em" | "1.5em";
export type TitleSize = "default" | "large" | "largeAlt";

export const collectiveSizeClassMap: BreakpointMap = {
  default: defaultSizeClassMap,
  sm: smallSizeClassMap,
  md: mediumSizeClassMap,
  lg: largeSizeClassMap,
  xl: extraLargeSizeClassMap,
  "2xl": twoExtraLargeSizeClassMap,
  "3xl": threeExtraLargeSizeClassMap,
};

export const collectiveAlignClassMap: BreakpointMap = {
  default: alignClassMap,
  sm: smallAlignClassMap,
  md: mediumAlignClassMap,
  lg: largeAlignClassMap,
  xl: extraLargeAlignClassMap,
  "2xl": twoExtraLargeAlignClassMap,
  "3xl": threeExtraLargeAlignClassMap,
};

export const collectiveWhitespaceClassMap: BreakpointMap = {
  default: defaultWhitespaceClassMap,
  sm: smallWhitespaceClassMap,
  md: mediumWhitespaceClassMap,
  lg: largeWhitespaceClassMap,
  xl: extraLargeWhitespaceClassMap,
  "2xl": twoExtraLargeWhitespaceClassMap,
  "3xl": threeExtraLargeWhitespaceClassMap,
};
